import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, map } from "rxjs";
import { ClustersService } from "../clusters.service";
import { urlBuilder } from "@zixi/shared-utils";
import { Breadcrumb } from "src/app/services/breadcrumbs.service";
import { Cluster } from "../cluster";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: "root"
})
export class ClusterBreadcrumbsResolverService implements Resolve<Breadcrumb[]> {
    constructor(private clustersService: ClustersService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Observable<never> {
        return this.clustersService.refreshCluster(urlBuilder.decode(route.params["clusterId"]), false).pipe(
            map((cluster: Cluster) => [
                {
                    label: cluster.name,
                    url: state.url
                }
            ])
        );
    }
}

@Injectable({
    providedIn: "root"
})
export class ClusterNewBXBreadcrumbsResolverService implements Resolve<Breadcrumb[]> {
    constructor(private clustersService: ClustersService, private translate: TranslateService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Breadcrumb[]> | Observable<never> {
        return this.clustersService.refreshCluster(urlBuilder.decode(route.params["clusterId"]), false).pipe(
            map((cluster: Cluster) => [
                {
                    label: this.translate.instant("BROADCASTER_CLUSTERS"),
                    url: "/" + route.url[0].path
                },
                {
                    label: cluster.name,
                    url: "/" + route.url[0].path + "/" + route.url[1].path + "/" + route.url[2].path
                },
                {
                    label: this.translate.instant("NEW_BROADCASTER")
                }
            ])
        );
    }
}

@Injectable({
    providedIn: "root"
})
export class ClusterFormBreadcrumbsResolverService implements Resolve<Breadcrumb[]> {
    constructor(private clustersService: ClustersService, private translate: TranslateService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Breadcrumb[]> | Observable<never> {
        return this.clustersService.refreshCluster(urlBuilder.decode(route.params["clusterId"]), false).pipe(
            map((cluster: Cluster) => [
                {
                    label: this.translate.instant("BROADCASTER_CLUSTERS"),
                    url: "/" + route.url[0].path
                },
                {
                    label: cluster.name,
                    url: "/" + route.url[0].path + "/" + route.url[1].path + "/" + route.url[2].path
                },
                {
                    label:
                        this.translate.instant(route.params.action.toUpperCase()) +
                        " " +
                        this.translate.instant("BROADCASTER_CLUSTER")
                }
            ])
        );
    }
}
