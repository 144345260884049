import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";
import { MatMenuModule } from "@angular/material/menu";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Routes
import { ClustersRouting } from "./clusters.routing";

// Components
import { ClusterListComponent } from "./cluster-list/cluster-list.component";
import { ClusterFormComponent } from "./cluster-form/cluster-form.component";
import { ClusterComponent } from "./cluster/cluster.component";
import { ClusterDetailsComponent } from "./cluster/cluster-details/cluster-details.component";
import { ClusterBroadcastersComponent } from "./cluster/cluster-broadcasters/cluster-broadcasters.component";
import { ClusterRedirectionRulesComponent } from "./cluster/cluster-redirection-rules/cluster-redirection-rules.component";
import { BroadcasterFormComponent } from "../../components/broadcasters/broadcaster-form/broadcaster-form.component";
import { BroadcasterComponent } from "./broadcaster/broadcaster.component";
import { DragDropModule } from "@angular/cdk/drag-drop";

//
import { BroadcasterActiveObjectsComponent } from "./broadcaster/broadcaster-active-objects/broadcaster-active-objects.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        MatMenuModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        ClustersRouting,
        DragDropModule,
        MatTooltipModule,
        TourMatMenuModule
    ],
    declarations: [
        ClusterListComponent,
        ClusterFormComponent,
        ClusterComponent,
        ClusterDetailsComponent,
        ClusterBroadcastersComponent,
        ClusterRedirectionRulesComponent,
        BroadcasterFormComponent,
        BroadcasterComponent,
        BroadcasterActiveObjectsComponent
    ]
})
export class ClustersModule {}
