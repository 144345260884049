import { TranslateService } from "@ngx-translate/core";
import { BaseLayout } from "../../../helpers/base-layout";
import { GraphsService } from "src/app/services/graphs.service";
import { UsersService } from "../../account-management/users/users.service";
import { SharedService } from "src/app/services/shared.service";
import { Constants } from "src/app/constants/constants";
import {
    Details,
    DetailsLayout
} from "src/app/components/shared/new-details-page/details-section/details-section.component";
import {
    WidgetHeader,
    WidgetHeaderLayout
} from "src/app/components/shared/new-details-page/widget-section/widget-section-header/widget-section-header.component";
import {
    Widget,
    WidgetLayout
} from "src/app/components/shared/new-details-page/widget-section/widget-section.component";
import { ZxHistoryGraphComponent } from "src/app/components/shared/zx-history-graph/zx-history-graph.component";
import { ZxEventsComponent } from "src/app/components/shared/zx-events/zx-events.component";
import { BroadcasterDetailsComponent } from "./broadcaster-details/broadcaster-details.component";
import { BroadcasterActiveObjectsComponent } from "./broadcaster-active-objects/broadcaster-active-objects.component";
import { ZxObjectTrackingMainListComponent } from "src/app/components/shared/zx-object-tracking/main-list/zx-object-tracking-main-list.component";
import { ZxAdvancedNotesComponent } from "src/app/components/shared/zx-advanced-notes/zx-advanced-notes.component";
import { ZxAgentzOsDataComponent } from "src/app/components/shared/zx-agentz-os-data/zx-agentz-os-data.component";
import { ObjectIncidentListComponent } from "src/app/components/shared/object-incidents/object-incident-list.component";
import { Cluster } from "./../cluster";
import { Broadcaster } from "./../../../models/shared";

export abstract class BroadcasterLayouts extends BaseLayout {
    abstract cluster: Cluster;
    abstract broadcaster: Broadcaster;
    abstract canEditBroadcaster: boolean;
    // TODO maybe need to change it like this: something with .bind(this) fill weird to me...
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    // refreshSourcePromise() {}
    abstract refreshBroadcasterPromise();
    constructor(
        protected translate: TranslateService,
        protected graphsService: GraphsService,
        protected userService: UsersService,
        protected sharedService: SharedService
    ) {
        super(userService, sharedService, translate);
    }
    keys = {
        primaryDetails: [
            //The order is important for the layout
            "CPU",
            "IN_BITRATE",
            "GPU",
            "SOURCES",
            "UP_TIME",
            "CLUSTER_TAGS"
        ],
        secondaryDetails: [
            //The order is important for the layout
            "PUBLIC_IP",
            "PRIVATE_IP",
            "VERSION",
            "AGENTZ",
            "OPEN",
            "API_CREDENTIALS",
            "LOCATION",
            "NETWORK",
            "CONNECTION_STATUS",
            "PRIMARY",
            "HOST_ID",
            "HOSTNAME",
            "INSTANCE_TYPE",
            "INSTANCE_ID",
            "ACTIVATION_KEY_LINK",
            "SYSTEM_LOGS",
            "CREATED_AT"
        ],
        nonDeletedSecondaryDetails: ["PUBLIC_IP", "VERSION", "AGENTZ", "OPEN", "LOCATION", "NETWORK"],
        widgets: [
            //The order is important for the layout
            "HISTORY",
            "ACTIVE_OBJECTS",
            "INCIDENTS",
            "DETAILS",
            "EVENTS",
            "CHANGES",
            "NOTES",
            "AGENTZ_OS"
        ]
    } as const;

    protected scopeName = "broadcasterDetails";
    constants = Constants;

    protected translatedNames = {
        ...this.translateList(
            [...this.keys.primaryDetails, ...this.keys.secondaryDetails, ...this.keys.widgets],
            this.translate
        ),
        RAM: this.translate.instant("RAM"),
        HDD: this.translate.instant("HDD"),
        OUT_BITRATE: this.translate.instant("OUT_BITRATE"),
        MEM: this.translate.instant("MEM"),
        ENC: this.translate.instant("ENC"),
        DEC: this.translate.instant("DEC"),
        INPUTS: this.translate.instant("INPUTS"),
        ADAPTIVE: this.translate.instant("ADAPTIVE"),
        BROADCASTER_CLUSTER: this.translate.instant("BROADCASTER_CLUSTER"),
        BROADCASTER: this.translate.instant("BROADCASTER"),
        OUTPUTS: this.translate.instant("OUTPUTS"),
        UP_TIME: `${this.translate.instant("UP_TIME")} [${this.translate.instant("HH:mm:ss")}]`
    };

    primaryDetailsDefaultLayout = this.keys.primaryDetails.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isHidden: false
    })) satisfies DetailsLayout<typeof this.keys.primaryDetails>[];

    protected bxPrimaryDetails: Details[] = this.primaryDetailsDefaultLayout.map(layout => ({
        ...layout,
        data: this.getLayoutDataByKey(layout.key)
    }));

    protected secondaryDetailsDefaultLayout = this.keys.secondaryDetails.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isHidden: false,
        isDeleted: !this.keys.nonDeletedSecondaryDetails.includes(key as any)
    })) satisfies DetailsLayout<typeof this.keys.secondaryDetails>[];

    protected bxSecondaryDetails: Details[] = this.secondaryDetailsDefaultLayout.map(layout => ({
        ...layout,
        data: [{ title: layout.title, content: "" }]
    }));

    protected widgetHeadersDefaultLayout = this.keys.widgets.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isSelected: true,
        isHidden: false,
        isExpanded: false
    })) satisfies WidgetHeaderLayout<typeof this.keys.widgets>[];

    protected bxWidgetHeaders = this.widgetHeadersDefaultLayout.map(layout => ({
        ...layout,
        fa_icon: this.getIconLayoutKey(layout.key)
    })) satisfies WidgetHeader<typeof this.keys.widgets>[];

    protected widgetsDefaultLayout = this.keys.widgets.reduce((obj, key, index) => {
        obj[key] = {
            title: this.translatedNames[key],
            id: this.translatedNames[key],
            key,
            index,
            ...this.constants.defaultWidgetLayout
        };
        return obj;
    }, {} as Record<(typeof this.keys.widgets)[number], WidgetLayout>) satisfies Record<
        (typeof this.keys.widgets)[number],
        WidgetLayout
    >;

    broadcasterWidgets: Widget[] = [
        {
            ...this.widgetsDefaultLayout.HISTORY,
            component: ZxHistoryGraphComponent,
            inputs: {
                object: () => ({ broadcaster: this.broadcaster }),
                graphUrl: () => this.graphsService.custom(this.graphsService.broadcaster(this.broadcaster))
            }
        },
        {
            ...this.widgetsDefaultLayout.EVENTS,
            component: ZxEventsComponent,
            inputs: {
                objects: () => ({ broadcaster: this.broadcaster }),
                id: () => this.broadcaster.id,
                autoRows: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.DETAILS,
            component: BroadcasterDetailsComponent,
            inputs: {
                broadcaster: () => this.broadcaster,
                cluster: () => this.cluster,
                canEdit: () => this.canEditBroadcaster
            }
        },
        {
            ...this.widgetsDefaultLayout.ACTIVE_OBJECTS,
            component: BroadcasterActiveObjectsComponent,
            inputs: {
                broadcasterId: () => this.broadcaster.id,
                autoRows: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.CHANGES,
            component: ZxObjectTrackingMainListComponent,
            inputs: {
                objectId: () => this.broadcaster.id,
                objectName: () => this.broadcaster.name,
                objectType: () => "broadcaster",
                objectApiType: () => "broadcasters",
                autoRows: () => false,
                refreshObjectDataFunction: () => this.refreshBroadcasterPromise.bind(this)
            }
        },
        {
            ...this.widgetsDefaultLayout.NOTES,
            component: ZxAdvancedNotesComponent,
            inputs: {
                model: () => this.broadcaster,
                type: () => "broadcaster",
                id: () => this.broadcaster.id,
                canEdit: () => this.canEditBroadcaster
            }
        },
        {
            ...this.widgetsDefaultLayout.AGENTZ_OS,
            component: ZxAgentzOsDataComponent,
            inputs: {
                broadcasterId: () => this.broadcaster.id
            }
        },
        {
            ...this.widgetsDefaultLayout.INCIDENTS,
            component: ObjectIncidentListComponent,
            inputs: {
                objectId: () => this.broadcaster.id,
                objectType: () => "broadcaster",
                bordered: () => false
            }
        }
    ];

    private getIconLayoutKey(key: (typeof this.keys.widgets)[number]): string | string[] {
        switch (key) {
            case "DETAILS":
                return "align-justify";
            case "HISTORY":
                return "chart-line";
            case "EVENTS":
                return "exclamation-triangle";
            case "ACTIVE_OBJECTS":
                return "wave-pulse";
            case "CHANGES":
                return "clock-rotate-left";
            case "NOTES":
                return ["far", "sticky-note"];
            case "AGENTZ_OS":
                return "database";
            case "INCIDENTS":
                return "file-exclamation";
            default:
                throw new Error("Unknown key");
        }
    }

    private getLayoutDataByKey(key: (typeof this.keys.primaryDetails)[number]) {
        switch (key) {
            case "CPU":
                return [
                    {
                        title: this.translatedNames.CPU,
                        content: "",
                        statusClass: ""
                    },
                    {
                        title: this.translatedNames.RAM,
                        content: "",
                        statusClass: ""
                    },
                    {
                        title: this.translatedNames.HDD,
                        content: "",
                        statusClass: ""
                    }
                ];
            case "IN_BITRATE":
                return [
                    {
                        title: this.translatedNames.IN_BITRATE,
                        content: "-",
                        statusClass: ""
                    },
                    {
                        title: this.translatedNames.OUT_BITRATE,
                        content: "-",
                        statusClass: ""
                    }
                ];
            case "GPU":
                return [
                    {
                        title: this.translatedNames.GPU,
                        content: "-",
                        statusClass: ""
                    },
                    {
                        title: this.translatedNames.MEM,
                        content: "-",
                        statusClass: ""
                    },
                    {
                        title: this.translatedNames.ENC,
                        content: "-",
                        statusClass: ""
                    },
                    {
                        title: this.translatedNames.DEC,
                        content: "-",
                        statusClass: ""
                    }
                ];
            case "SOURCES":
                return [
                    {
                        title: this.translatedNames.INPUTS,
                        content: "0",
                        statusClass: "",
                        fa_icon: "video"
                    },
                    {
                        title: this.translatedNames.ADAPTIVE,
                        content: "0",
                        statusClass: "",
                        fa_icon: "project-diagram"
                    },
                    {
                        title: this.translatedNames.OUTPUTS,
                        content: "0",
                        statusClass: "",
                        fa_icon: "sign-out-alt"
                    }
                ];
            case "CLUSTER_TAGS":
                return [
                    {
                        title: this.translatedNames.CLUSTER_TAGS,
                        content: ""
                    }
                ];
            case "UP_TIME":
                return [
                    {
                        title: this.translatedNames.UP_TIME,
                        content: ""
                    }
                ];
            default:
                throw new Error("Unknown key");
        }
    }
}
